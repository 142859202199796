:root {
  --main-bg: hsl(217, 54%, 11%);
  --white: hsl(0, 0%, 100%);
  --card-bg: hsl(216, 50%, 16%);
  --soft-blue: hsl(215, 51%, 70%);
  --cyan: hsl(178, 100%, 50%);
}

.nft-title {
  margin-top: 0;
}

.nft-card > * {
  margin-bottom: 20px;
}

.nft-card {
  background-color: var(--main-bg);
  color: var(--white);
  font-family: 'Outfit', Arial, Helvetica, sans-serif;
  width: 300px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--card-bg);
  transition: all 0.25s;
}

.nft-card:hover {
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 20px 1px #000000;
  -moz-box-shadow: 0px 0px 20px 1px #000000;
  box-shadow: 0px 0px 20px 1px #000000;
}

.nft-img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 5px !important;
}

.nft-info {
  display: flex;
  justify-content: space-between;
}

.nft-card-footer {
  margin-top: auto;
}

.price-container {
  color: hsl(178, 100%, 50%);
}

.price-container a {
  color: hsl(178, 100%, 50%);
}
.price-container a {
  color: hsl(178, 100%, 50%);
}
.price-container a:link {
  color: hsl(178, 100%, 50%);
}
.price-container a:visited {
  color: hsl(178, 100%, 50%);
}
.price-container a:hover {
  color: hsl(178, 100%, 50%);
}
.price-container a:active {
  color: hsl(178, 100%, 50%);
}

.date-container {
  display: flex;
  align-items: center;
  color: var(--soft-blue);
}

.owner-info {
  display: flex;
  align-items: center;
  color: var(--soft-blue);
}

.author-name {
  color: var(--white);
}

.avatar-img {
  width: 25px;
  margin-right: 10px;
  border-radius: 100%;
  border: #fff 1px solid;
}

.contract-info {
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-elillipsis {
  text-overflow: ellipsis;
}

.nft-description,
.token-info {
  color: var(--soft-blue);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.token-id {
  cursor: pointer;
}

.d-hide {
  opacity: 0;
}

.tooltip {
  background-color: #fff;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1.2rem;
}

.nft-card-container {
  border-radius: 10px;
  box-shadow: -1px 1px 12px 5px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: -1px 1px 12px 5px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: -1px 1px 12px 5px rgba(0, 0, 0, 0.33);
  width: 100%;
}

.nft-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
}

a.white {
  color: #fff;
}
a.white:link {
  color: #fff;
}
a.white:visited {
  color: #fff;
}
a.white:hover {
  color: #b1b1b1;
}
a.white:active {
  color: #b1b1b1;
}

a.no-underline {
  text-decoration: none;
}
