@import url('https://fonts.googleapis.com/css2?family=Shizuru&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.main-header {
  text-align: center;
  text-overflow: clip;
}

.main-header h1 {
  margin: 0;
  font-family: 'Shizuru';
  font-size: 3rem;
}

.main-header a:hover img {
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 20px 1px #000000;
  -moz-box-shadow: 0px 0px 20px 1px #000000;
  box-shadow: 0px 0px 20px 1px #000000;
}

.header-avatar {
  margin-top: 20px;
  border-radius: 100%;
  border: 3px solid #000;
  width: 100px;
}

.main-footer {
  margin-bottom: 20px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
